import { render, staticRenderFns } from "./MoPublishConfigFeedList.vue?vue&type=template&id=e97a84ca&scoped=true&"
import script from "./MoPublishConfigFeedList.vue?vue&type=script&lang=js&"
export * from "./MoPublishConfigFeedList.vue?vue&type=script&lang=js&"
import style0 from "./MoPublishConfigFeedList.vue?vue&type=style&index=0&id=e97a84ca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e97a84ca",
  null
  
)

export default component.exports